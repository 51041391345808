import {
  isLocalStorageNameSupported,
  isPalm
} from '@ext/ndsbase/Resources/Public/Assets/Js/Base/helperLib'

const search = document.querySelector('.js-solr-search')
const toggleElems = document.querySelectorAll('.js-solr-toggle')
const form = search.querySelector('.js-solr-search-form')
const input = search.querySelector('.js-solr-input')
const clearBtn = search.querySelector('.js-solr-clear')
const suggestionsSelect = search.querySelector('.js-solr-suggestions-select')
const suggestionsWrap = search.querySelector('.js-solr-suggestions-wrap')
const results = search.querySelector('.js-solr-results')
const resultsWrap = search.querySelector('.js-solr-results-wrap')
const noResults = search.querySelector('.js-solr-count-noresults')
const nextPage = search.querySelector('.js-solr-nextpage')
const nextPageWrap = search.querySelector('.js-solr-nextpage-wrap')
const count = search.querySelector('.js-solr-count')
const countWrap = search.querySelector('.js-solr-count-wrap')
const filterWrap = search.querySelector('.js-solr-filter-wrap')
const filterBox = resultsWrap.querySelector('.js-solr-filter-box')
const filters = search.querySelector('.js-solr-filters')
const quicksearchInputs = document.querySelectorAll('.js-solr-quicksearch-input')
const baseURL = getBaseURL()

let vars = {
  suggestions: [],
  resultCount: 0,
  docs: {},
  filter: {},
  minWordLength: 3,
  searchParams: {q: '', selectedFilter: [], page: 0},
  suggestionTimeout: null,
  trackSolr: false,
  fallbackImage: '',
  fallbackImageCopyright: ''
}

const init = function() {
  toggleElems.forEach((solrToggleElem) => {
    solrToggleElem.addEventListener('click', () => {
      if (document.getElementById('js-overlay-solr') && document.getElementById('js-overlay-solr').classList.contains('is-active')) {
        input.focus()
      }
    })
  })

  if (search.dataset.track) {
    vars.trackSolr = search.dataset.track
  }

  if (search.dataset.savesearchterm === "1") {
    vars.saveSearchTerm = true
  }

  if (search.dataset.fallback) {
    vars.fallbackImage = search.dataset.fallback
  }

  if (search.dataset.fallbackCopyright) {
    vars.fallbackImageCopyright = search.dataset.fallbackCopyright
  }

  if (vars.saveSearchTerm && localStorage.getItem('solrSearchTerm') !== null) {
    vars.searchParams.q = localStorage.getItem('solrSearchTerm')
    input.value = vars.searchParams.q
    clearBtn.classList.remove('hidden')
  }

  form.addEventListener('submit', function(e) {
    e.preventDefault()
    if (vars.searchParams.q.length >= vars.minWordLength) {
      startSearch()
    }
  })

  input.addEventListener('input', updateSearchterm)

  suggestionsSelect.addEventListener('change', selectSuggestion)

  nextPage.addEventListener('click', nextPageEvent)

  clearBtn.querySelectorAll('svg[data-url]').forEach((svgElem) => {
    loadSvg(svgElem)
  })

  clearBtn.addEventListener('click', clearSearch)
}

const startSearch = function(filterToggled) {
  hideSuggestions()
  hide(noResults)

  if (vars.searchParams.q === '') {
    clearSearch()
    show(noResults)
    return
  }

  vars.searchParams.page = 0

  let xhr = new XMLHttpRequest()
  xhr.open('POST', baseURL + '?ndssolr=search&no_cache=1', true)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send(JSON.stringify(vars.searchParams))
  xhr.onload = function() {
    if (xhr.status === 200) {
      let response = JSON.parse(xhr.responseText)

      if (vars.trackSolr && parseInt(vars.trackSolr) === 1 && !filterToggled) {
        dataLayer.push(
          {
            'event': 'trackSolr',
            'solrSearchQuery': location.pathname + '?suche=' + vars.searchParams.q + '&page=0&results=' + response.resultCount
          }
        )
      }

      if (response.hasOwnProperty('resultCount')) {
        vars.resultCount = response.resultCount
        vars.docs = response.docs
        let filters = response.filter
        for(let key in filters) {
          if (filters.hasOwnProperty(key)) {
            if (filters[key].count === 0) {
              delete filters[key]
            }
          }
        }
        vars.filter = filters
        updateResultCount()
        updateDocs()
        if (!filterToggled) {
          updateFilters()
        }
      }

      resultsWrap.classList.add('open')
    }
  }

  if (vars.saveSearchTerm && isLocalStorageNameSupported()) {
    localStorage.setItem('solrSearchTerm', vars.searchParams.q)
  }
}

const updateResultCount = function() {
  if (vars.resultCount > 0) {
    countWrap.querySelector('.js-solr-count-number').innerHTML = vars.resultCount
    show(count)
    hide(noResults)
    show(filterBox)
  } else {
    hide(count)
    show(noResults)
    hide(filterBox)
  }

  if (!vars.filter || !Object.keys(vars.filter).length) {
    hide(filterBox)
  }
}

const clearSearch = function() {
  vars.searchParams.q = ''
  vars.searchParams.selectedFilter = []
  clearBtn.classList.add('hidden')
  hide(filterWrap)
  hide(nextPageWrap)
  updateFilters()
  results.innerHTML = ''
  resultsWrap.classList.remove('open')
  input.value = ''

  quicksearchInputs.forEach((quicksearchInput) => {
    quicksearchInput.value = ''
  })
}

const interpolate = (template, parameters) => {
  const names = Object.keys(parameters)
  const values = Object.values(parameters)

    return new Function(...names, `return \`${template}\`;`)(...values)
}

const updateFilters = function() {
  let filterHtml = ''

  for(let key in vars.filter) {
    if (vars.filter.hasOwnProperty(key)) {
      let filterInfo = vars.filter[key]
      const filterTemplate = search.querySelector('.js-solr-filter-template').innerHTML
      filterHtml += interpolate(filterTemplate, {
        key, filterInfo
      })
    }
  }

  filters.innerHTML = filterHtml

  search.querySelectorAll('.js-solr-filter').forEach(function(btn) {
    loadSvg(btn.querySelector('svg[data-url]'))

    btn.addEventListener('click', function(e) {
      toggleFilter(e, btn)
    })
  })
}

const updateDocs = function(nextPageDocs) {
  let resultHtml = '', docs = {}, nextPage = false

  if (nextPageDocs) {
    nextPage = true
    docs = nextPageDocs
  } else {
    docs = vars.docs
  }

  if (docs.length > 0) {
    docs.forEach(function(doc) {
      if (Array.isArray(doc.teaser)) {
        doc.teaser = doc.teaser[doc.teaser.length - 1]
      }
      if (doc.teaser.length > 60) {
        doc.teaser = doc.teaser.substring(0, 60) + '...'
      }
      if (doc.filePublicUrl === undefined) {
        doc.filePublicUrl = vars.fallbackImage
        doc.copyright_stringS = vars.fallbackImageCopyright
      }
      if (typeof doc.copyright_stringS !== 'undefined' && doc.copyright_stringS) {
        doc.copyright_stringS = '© ' + doc.copyright_stringS
      } else {
        doc.copyright_stringS = ''
      }
      const teaserTemplate = search.querySelector('.js-solr-result-template').innerHTML
      resultHtml += interpolate(teaserTemplate, {
        doc
      })
    })
  }

  if (nextPage) {
    const nextResults = new DOMParser().parseFromString(resultHtml, 'text/html').body.childNodes
    let first = nextResults[0]

    for(let elem of nextResults) {
      results.appendChild(elem);
    }

    first.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  } else {
    results.innerHTML = resultHtml
  }

  search.querySelectorAll('.js-solr-results svg[data-url]').forEach((svgElem) => {
    loadSvg(svgElem)
  })

  if (vars.resultCount <= vars.docs.length) {
    hide(nextPageWrap)
  } else {
    show(nextPageWrap)
  }
}

const updateSearchterm = function(ajax = true) {
  if (vars.suggestionTimeout) {
    clearTimeout(vars.suggestionTimeout)
  }

  let searchterm = input.value

  if (searchterm.length > 0) {
    clearBtn.classList.remove('hidden')
  } else {
    clearBtn.classList.add('hidden')
  }

  if (ajax) {
    vars.suggestionTimeout = setTimeout(function() {
      hideSuggestions()

      suggestionsSelect.options.length = 0
      vars.searchParams.q = searchterm

      if (searchterm.length >= vars.minWordLength) {
        let xhr = new XMLHttpRequest()
        xhr.open('POST', baseURL + '?ndssolr=suggestions&no_cache=1', true)
        xhr.setRequestHeader('Content-Type', 'application/json')
        xhr.send(JSON.stringify({searchterm: searchterm}))
        xhr.onload = function() {
          if (xhr.status === 200) {
            let response = JSON.parse(xhr.responseText)
            if (response && response.suggestions) {
              vars.suggestions = response.suggestions

              if (!vars.suggestions.find(suggestion => suggestion.value === searchterm)) {
                suggestionsSelect.options[0] = new Option(searchterm, searchterm, true, true)
                suggestionsSelect.options.length = 1
              }

              if (suggestionsSelect.dataset.prependLabel) {
                let prependOption = new Option(suggestionsSelect.dataset.prependLabel)
                prependOption.setAttribute('disabled', true)
                suggestionsSelect.options[0] = prependOption
              }

              vars.suggestions.forEach(function(entry) {
                let length = suggestionsSelect.options.length
                suggestionsSelect.options[length] = new Option(entry.text, entry.value)
              })

              suggestionsSelect.setAttribute('size', isPalm() ? 1 : suggestionsSelect.length)

              if (vars.suggestions.length > 0) {
                show(suggestionsWrap)
                window.addEventListener('click', handleSuggestionsClose)
              }
            }
          }
        }
      }
    }, 400)
  }
}

const handleSuggestionsClose = function() {
  hideSuggestions()
}

const hideSuggestions = function() {
  hide(suggestionsWrap)
  window.removeEventListener('click', handleSuggestionsClose)
}

const selectSuggestion = function() {
  if (this.value) {
    vars.searchParams.q = this.value
    search.querySelector('.js-solr-input').value = this.value
    hideSuggestions()
    suggestionsSelect.options.length = 0
    vars.suggestions = []
    startSearch()
  }
}

const nextPageEvent = function(e) {
  e.preventDefault()

  if (vars.docs.length < vars.resultCount) {
    vars.searchParams.page++
    let xhr = new XMLHttpRequest()
    xhr.open('POST', baseURL + '?ndssolr=search&no_cache=1', true)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.send(JSON.stringify(vars.searchParams))
    xhr.onload = function() {
      if (xhr.status === 200) {
        let response = JSON.parse(xhr.responseText)
        vars.docs = vars.docs.concat(response.docs)
        updateDocs(response.docs)
      }
    }
  }
}

const toggleFilter = function(e, btn) {
  const filter = btn.dataset.filter

  btn.classList.toggle('is-active')

  if (btn.dataset.count > 0) {
    if (vars.searchParams.selectedFilter.indexOf(filter) === -1) {
      vars.searchParams.selectedFilter.push(filter)
    } else {
      vars.searchParams.selectedFilter.splice(vars.searchParams.selectedFilter.indexOf(filter), 1)
    }
    startSearch(true)
  }
  e.preventDefault()
}

const show = function(elem) {
  if (elem) {
    elem.style.display = 'block'
  }
}

const hide = function(elem) {
  if (elem) {
    elem.style.display = 'none'
  }
}

const loadSvg = function(svgElem) {
  if (!svgElem) {
    return
  }
  const url = svgElem.getAttribute('data-url')
  if (url) {
    fetchSVG(url, svgElem)
  }
  svgElem.removeAttribute('data-url')
}

export function quickSearch(value = '') {
  vars.searchParams.q = value
  input.value = value
  updateSearchterm(false)
  startSearch()
}

init()
